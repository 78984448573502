// ==============================
// FEATURED POSTS style
// 自定义样式
// ==============================

  /* Handle Featured Posts */
  .feat-section {
	display: flex;
	min-width: 100%;
  }
  
  @media only screen and (max-width: 1450px) {
	.feat-section {
	  display: block;
	}
  
	.feat-section .feat-post {
	  margin: 1.5rem 0 !important;
	}
  }
  
  .feat-post {
	border: 1px solid goldenrod !important; 
	box-shadow: 0px 0px 5px 0px gold !important;
	margin: 0 0.25rem !important;
  }
  
  .feat-footer {
	justify-content: space-between;
  }
  .feat-footer a{
	text-decoration: none;
	color: #2d96bd !important;
	font-size: .875rem !important;
  }
  
  .feat-post {
	font-size: medium;
  }
  .feat-post .single-title a{
	text-shadow: 0px 0px 5px 0px gold !important;
	display: -moz-box;
	display: -webkit-box;
	display: box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
  }
  .feat-meta .feat-author a {
	font-size: small !important;
	font-weight: 600;
  }
  .p3 article {
	flex: 33%;
  }
  .p2 article {
	flex: 50%;
  }
  .p1 article {
	flex: 100%;
  }