// ==============================
// Custom style
// 自定义样式
// ==============================

/**
 All the overrides for website styles
*/
/* Handle Images in Posts*/
figure.imageleft {
	width: 50%;
	float: left; 
	margin-right: 1rem !important;
	margin-bottom: 1rem !important;
	border: none;
  }
  
  figure.imageleft fig-caption 
  {
	float: left;
	text-align: center;
  }
  
  figure.imageleft img {
	width: 90%;
	float: none !important; 
	margin: auto !important;
	border: none;
  }
  
  figure.imageright {
	width: 50%;
	float: right; 
	margin-left: 1rem !important;
	margin-bottom: 1rem !important;
	border: none;
  }
  
  figure.imageright fig-caption 
  {
	float: right;
	text-align: center;
  }
  
  figure.imageright img {
	width: 90%;
	float: none !important; 
	margin: auto !important;
	border: none;
  }
  
  .img-imageleft {
	float: left; 
	margin-right: 1rem !important;
	padding-bottom: 1rem !important;
	border: none;
  }
  
  .img-imageright {
	float: right; 
	margin-left: 1rem !important;
	margin-bottom: 1rem !important;
	border: none;
  }
  
  .blueglow {
	box-shadow:0px 0px 10px 2px #2d96bdba;
  }
  
  /* TOC Twitter feed*/
  .twitter-title {
	margin: 2rem 0 0.5rem 0;
	font-size: 1.2rem;
	font-weight: bold;
	text-transform: uppercase;
  }
  .twitterfeed {
	position: relative; 
	margin-top: 1rem;
  }
  .twitter-follow-button {
	  margin-bottom: -4px !important;
  }
  .twitter-tweet {
	  margin: auto !important;
  }